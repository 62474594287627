@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Bricolage Grotesque', sans-serif;


}